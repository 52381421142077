.hcm-tooltip-base {
  background-color: transparent;
  padding: 0;
  color: #FFF;
}

.hcm-tooltip-base-left::before,
.hcm-tooltip-base-bottom::before,
.hcm-tooltip-base-right::before,
.hcm-tooltip-base-top::before {
  border-color: transparent;
  overflow: auto;
}