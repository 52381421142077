.modal-backdrop {
  --bs-backdrop-zindex: 900;
  z-index: 900;
}

modal {
  --bs-backdrop-zindex: 900;
  z-index: var(--bs-modal-zindex);
}


@media screen and (max-width: 768px) {
  .modal-backdrop {
    height: calc(100vh - 71px);
  }
  .modal {
    height: calc(100vh - 71px);
  }
}